import * as React from "react"

const NotFoundPage = () => {
  return (
    <main>
      <title>Not found</title>

      <h1 className="text-4xl text-center font-extrabold mt-14">Page not found</h1>
    </main>
  )
}

export default NotFoundPage
